import React, { useState } from 'react';
import '../styles/style.css';

const StepTwo = ({ prevStep, handleSubmit, handleChange, values }) => {
  const [error, setError] = useState('');
  const [selectedAirportName, setSelectedAirportName] = useState('');

  // Handle the dropdown selection
  const handleAirportChange = (event) => {
    const selectedValue = event.target.value;

    // If no airport is selected, clear the state
    if (!selectedValue) {
      handleChange('airport')({ target: { value: '' } });
      handleChange('airportCode')({ target: { value: '' } });
      setSelectedAirportName('');
      return;
    }

    const selectedName = event.target.options[event.target.selectedIndex].text;
    setSelectedAirportName(selectedName);

    // Log the selected airport name
    // console.log('Selected Airport:', selectedName);

    // Update form state with the selected airport's code and name
    handleChange('airport')({ target: { value: selectedName } });
    handleChange('airportCode')({ target: { value: selectedValue } });  
  };

   // Handle checkbox state changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name)({ target: { value: checked } });
  };

   const handleFormSubmit = () => {

    // // quick hack
    // if(values.airportCode && !values.airport){
    //   values.airport = values.airportCode;
    // }

    // validate that both an aiport has been selected and that the terms have been accepted and combine error  messages if needed
    if (!values.airportCode && !values.terms) {
      setError('Please select an airport and accept the terms and conditions.');
    }
    else if(!values.airportCode) {
      setError('Please select an airport.');
    }
    else if(!values.terms) {
      setError('Please accept the terms and conditions.');
    }
     else {
      setError('');
      handleSubmit();
    }
  };

  return (
    <div className="welcome-content">
      <h1>Your Preferences</h1>
      {error && <div className="error-message">{error}</div>}

     <div className="autocomplete">
        <label htmlFor="departureAirport" className="airport-label">
          Please select your closest or favourite airport *:
        </label>
        <select
          id="departureAirport"
          name="departureAirport"
          value={values.airportCode || ''}
          onChange={handleAirportChange}
          required
        >
            <option value="">Select an airport</option>
            {/* <option value="ABZ">Aberdeen (ABZ)</option> */}
            <option value="BFS">Belfast International (BFS)</option>
            {/* <option value="BHD">Belfast City (BHD)</option> */}
            <option value="BHX">Birmingham (BHX)</option>
            <option value="BOH">Bournemouth (BOH)</option>
            <option value="BRS">Bristol (BRS)</option>
            <option value="CWL">Cardiff (CWL)</option>
            {/* <option value="ORK">Cork (ORK)</option> */}
            <option value="DUB">Dublin (DUB)</option>
            {/* <option value="DND">Dundee (DND)</option> */}
            <option value="EDI">Edinburgh (EDI)</option>
            <option value="EMA">East Midlands (EMA)</option>
            {/* <option value="EXT">Exeter (EXT)</option> */}
            {/* <option value="GCI">Guernsey (GCI)</option> */}
            <option value="GLA">Glasgow (GLA)</option>
            {/* <option value="INV">Inverness (INV)</option> */}
            {/* <option value="IOM">Isle of Man (IOM)</option> */}
            {/* <option value="JER">Jersey (JER)</option> */}
            <option value="LBA">Leeds Bradford (LBA)</option>
            <option value="LON">London Airports - All (LON)</option>
            <option value="LCY">London City Airport (LCY)</option>
            <option value="LGW">London Gatwick (LGW)</option>
            <option value="LHR">London Heathrow (LHR)</option>
            <option value="LTN">London Luton (LTN)</option>
            {/* <option value="SEN">London Southend (SEN)</option> */}
            <option value="STN">London Stansted (STN)</option>
            <option value="LPL">Liverpool (LPL)</option>
            <option value="MAN">Manchester (MAN)</option>
            <option value="NCL">Newcastle (NCL)</option>
            {/* <option value="NWI">Norwich (NWI)</option> */}
            <option value="SOU">Southampton (SOU)</option>

        </select>
      </div>

        <div className="checkbox-container">
        <input
          type="checkbox"
          id="marketingConsent"
          name="marketingConsent"
          checked={values.marketingConsent}
          onChange={handleCheckboxChange}
          className="checkbox-input"
        />
        <label htmlFor="marketingConsent">
           <strong>Get the EDT Insider Newsletter</strong> – Your free fortnightly-ish hit of EDT news, travel tips & epic day-trip inspiration, straight to your inbox! 📬
        </label>
      </div>

      <div className="checkbox-container">
        <input
          type="checkbox"
          id="flightNewsletterUpdates"
          name="flightNewsletterUpdates"
          checked={values.flightNewsletterUpdates}
          onChange={handleCheckboxChange}
          className="checkbox-input"
        />
        <label htmlFor="flightNewsletterUpdates">
           <strong>Get Weekly Flight Deals for {selectedAirportName || "my selected"} airport</strong>  – Handpicked fares, big savings, delivered for free every weekend! ✈️
        </label>
      </div>

      <div className="checkbox-container">
        <input
          type="checkbox"
          id="terms"
          name="terms"
          checked={values.terms}
          onChange={handleCheckboxChange}
          className="checkbox-input"
          required
        />
        <label htmlFor="terms">
          🫡 I agree to the <a href="/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a> and <a href="https://extremedaytrips.com/terms" target="_blank" rel="noreferrer">terms and conditions</a>
        </label>
      </div>

      <button onClick={handleFormSubmit} className="welcome-button">Continue</button>
      {/* <button onClick={prevStep} className="back-button">Back</button> */}
      {prevStep && <button onClick={prevStep} className="back-button">Back</button>}
    </div>
  );
};

export default StepTwo;
