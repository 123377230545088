// src/components/SearchForm.js
import React, { useRef, useEffect } from 'react';
import datepicker from 'js-datepicker';
import 'js-datepicker/dist/datepicker.min.css';
import '../styles/Search.css';

const SearchForm = ({
  searchParams,
  handleAirportChange,
  handleDateChange,
  handleToggleChange,
  handleSubmit,
  isLoading,
  tripType,
}) => {
  const dateInputRef = useRef(null);

  useEffect(() => {
    if (dateInputRef.current) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const picker = datepicker(dateInputRef.current, {
        onSelect: (instance, date) => {
          const timezoneOffset = date.getTimezoneOffset() * 60000;
          const localAdjustedDate = new Date(date.getTime() - timezoneOffset);
          const formattedDate = localAdjustedDate.toISOString().split('T')[0];
          handleDateChange(formattedDate);
        },
        minDate: tomorrow,
      });

      return () => {
        if (picker) {
          picker.remove();
        }
      };
    }
  }, [handleDateChange]);

  return (
    <form className="search-form" onSubmit={handleSubmit} autoComplete="off">
      {/* Departure Airport Field */}
      <div className="search-field">
        <label htmlFor="departureAirport">Departure Airport</label>
        <select
          id="departureAirport"
          name="departureAirport"
          value={searchParams.departureAirport}
          onChange={handleAirportChange}
          required
          disabled={isLoading}
        >
          {/* Airport options */}
            <option value="">Select an airport</option>
            {/* <option value="ABZ">Aberdeen (ABZ)</option> */}
            <option value="BFS">Belfast International (BFS)</option>
            {/* <option value="BHD">Belfast City (BHD)</option> */}
            <option value="BHX">Birmingham (BHX)</option>
            <option value="BOH">Bournemouth (BOH)</option>
            <option value="BRS">Bristol (BRS)</option>
            <option value="CWL">Cardiff (CWL)</option>
            {/* <option value="ORK">Cork (ORK)</option> */}
            <option value="DUB">Dublin (DUB)</option>
            {/* <option value="DND">Dundee (DND)</option> */}
            <option value="EDI">Edinburgh (EDI)</option>
            <option value="EMA">East Midlands (EMA)</option>
            {/* <option value="EXT">Exeter (EXT)</option> */}
            {/* <option value="GCI">Guernsey (GCI)</option> */}
            <option value="GLA">Glasgow (GLA)</option>
            {/* <option value="INV">Inverness (INV)</option> */}
            {/* <option value="IOM">Isle of Man (IOM)</option> */}
            {/* <option value="JER">Jersey (JER)</option> */}
            <option value="LBA">Leeds Bradford (LBA)</option>
            <option value="LON">London Airports - All (LON)</option>
            <option value="LCY">London City Airport (LCY)</option>
            <option value="LGW">London Gatwick (LGW)</option>
            <option value="LHR">London Heathrow (LHR)</option>
            <option value="LTN">London Luton (LTN)</option>
            {/* <option value="SEN">London Southend (SEN)</option> */}
            <option value="STN">London Stansted (STN)</option>
            <option value="LPL">Liverpool (LPL)</option>
            <option value="MAN">Manchester (MAN)</option>
            <option value="NCL">Newcastle (NCL)</option>
            {/* <option value="NWI">Norwich (NWI)</option> */}
            <option value="SOU">Southampton (SOU)</option>
        </select>
      </div>

      {/* Departure Date Field */}
      <div className="search-field">
        <label htmlFor="departureDate">Departure Date</label>
        <input
          type="text"
          id="departureDate"
          name="departureDate"
          ref={dateInputRef}
          value={searchParams.departureDate}
          onChange={(e) => handleDateChange(e.target.value)}
          disabled={isLoading}
          required
        />
      </div>

      {/* Trip Type Toggle */}
      <div className="search-field search-field-toggle">
        <div className="toggle-container">
          <span>Day Trip</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={tripType === 'oneNightTrip'}
              onChange={handleToggleChange}
            />
            <span className="slider round"></span>
          </label>
          <span>One Night Trip</span>
        </div>
      </div>

      {/* Submit Button */}
      <button type="submit" className="search-button" disabled={isLoading}>
        Search Flights
      </button>
    </form>
  );
};

export default SearchForm;
