// src/SignupProcessor.js

import { app } from '../firebaseConfig'; // Ensure you are exporting 'app' from firebaseConfig.js
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';

const db = getFirestore(app); // Initialize Firestore using the Firebase app
const auth = getAuth(app); // Initialize Firebase Authentication

const createUser = async (email, password, userData, isSocialSignIn = false) => {
  try {
    
    let user;

    if (!isSocialSignIn) {
      // Create the user in Firebase Auth if they haven't signed in via social
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      user = userCredential.user;
    } else {
      // If signed in via Google, use the existing authenticated user
      user = auth.currentUser;
    }

    if (!user) {
      throw new Error("User not authenticated");
    }

    // Set the document ID in the 'users' collection to the user's UID
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      ...userData
    });

   // Axios instance
const api = axios.create({
   baseURL: 'https://brevo-middleware-767212907233.europe-west2.run.app',
   headers: {
    'Content-Type': 'application/json'
  }
});

  // Create or update contact
  try {
    const contactData = {
      email: email,
      flightAlerts: userData.flightNewsletterUpdates,
      edtNewsletter: userData.marketingConsent,
      airportCode: userData.airport
    };

    //console.log('📤 Sending contact data to middleware:', contactData);

    const response = await api.post('/createContact', contactData);

    //console.log('✅ API Response:', response.data);
    
  } catch (error) {
    console.error('❌ Error creating contact:', error.response?.data || error.message);
    throw error;
  }
 
    // Create a document in the 'users_public' collection with 'firstName', 'lastName', and 'uid'
    const { firstName, lastName } = userData;

    await setDoc(doc(db, 'users_public', user.uid), {
      uid: user.uid,
      firstName: firstName,
      lastName: lastName
    });

    console.log('User created with UID:', user.uid);
    return user;
  } catch (error) {
    console.error('Error creating user:', error.message);
    throw error;
  }
};

export { createUser, auth };